import styled from '@emotion/styled';
import React, { ChangeEvent, ReactElement, useEffect, useMemo, useState } from 'react';

import UploadIcon from '../../assets/upload_icon.svg';
import ImageIcon from '../../assets/image_icon.svg';
import QrScanner from 'qr-scanner';
import CircularProgress from '../../assets/CircularProgress';
import { copyToClipboard } from '../../utils/link';
import { Linkify } from '../Linkify';
import { useNavigate } from 'react-router-dom';

type DateType = {
  isLoading: boolean;
  successUpload: boolean;
  fileData: undefined | string;
  filePath: undefined | string;
};

const INITIAL_STATE: DateType = {
  isLoading: false,
  successUpload: false,
  fileData: undefined,
  filePath: undefined,
};

function UploadSection(): ReactElement {
  const [data, setData] = useState(INITIAL_STATE);
  const [isDragging, setIsDragging] = useState(false);
  const navigate = useNavigate();
  
  const scanFromImageHandler = async (file: File) => {
    setData(INITIAL_STATE);

    if (file === undefined) {
      setData(INITIAL_STATE);
      alert('이미지를 가져오지 못했습니다.');
      return;
    }

    setData({ ...INITIAL_STATE, isLoading: true, filePath: URL.createObjectURL(file) });

    try {
      const result = await QrScanner.scanImage(file, { returnDetailedScanResult: true });
      
      setData((prev: DateType) => {
        if (prev === undefined)
          return {
            ...INITIAL_STATE,
            isLoading: true,
            fileData: result.data,
            filePath: URL.createObjectURL(file),
          };
        return {
          ...prev,
          isLoading: true,
          fileData: result.data,
          filePath: URL.createObjectURL(file),
        };
      });
    } catch (e) {
      setData(INITIAL_STATE);
      alert('이미지에서 QR코드를 찾지 못했습니다.');
      return;
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    if (file) {
      scanFromImageHandler(file);
    }
    event.target.value = '';
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    if (file) {
      scanFromImageHandler(file);
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (data.isLoading) {
      timer = setTimeout(() => {
        navigate(`/result?result=${data.fileData}&blob=${data.filePath}`);
      }, 2000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [data.fileData, data.filePath, data.isLoading, navigate]);

  const renderImageSection = useMemo(() => {
    if (data.isLoading) return <CircularProgress />;
    return <ImageIconImg src={ImageIcon} />;
  }, [data.isLoading]);

  const renderUploadButton = useMemo(() => {
    if (data.isLoading) return <CircularProgress color="white" />;
    return <p>이미지 업로드</p>;
  }, [data.isLoading]);

  return (
    <Wrapper
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      isDragging={isDragging}
    >
      <Main htmlFor="input-image">
        <ImagePreviewSection>{renderImageSection}</ImagePreviewSection>
        <UploadButton>
          <UploadIconImg src={UploadIcon} />
          {renderUploadButton}
          <input
            type="file"
            id="input-image"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </UploadButton>
      </Main>
      <Footer>사진 속 QR코드의 정보를 분석해서 알려드려요</Footer>
    </Wrapper>
  );
}

export default UploadSection;

const Wrapper = styled.div<{ isDragging: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  max-width: 18.75rem;
  border-radius: 1.25rem;
  box-shadow: 0 0 2.5rem rgb(0 0 0 / 10%);
  border: ${({ isDragging }) => (isDragging ? '2px dashed #0f70e6' : '2px dashed #d3d3d3')};
  background-color: ${({ isDragging }) => (isDragging ? '#f0f8ff' : 'white')};
`;

const Main = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 32px;
  cursor: pointer;
`;

const ImagePreviewSection = styled.div`
  margin: 3rem 6.25rem 2.1875rem 6.25rem;
`;

const ImageIconImg = styled.img`
  width: 5rem;
`;

const PreviewImage = styled.img`
  width: 5rem;
  border-radius: 1.25rem;
`;

const UploadButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 0.75rem 2rem;
  background-color: #0f70e6;
  border-color: #0f70e6;
  color: #fff;
  border-radius: 6.1875rem;
  font-size: 1.0625rem;
  line-height: 1.5;
  font-weight: 700;
  cursor: pointer;
`;

const UploadIconImg = styled.img`
  width: 1.25rem;
`;

const Footer = styled.div`
  width: 100%;
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.03);
  text-align: center;
  color: #747474;
  font-size: 12.8px;
  font-weight: 400;
`;
