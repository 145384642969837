import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import { Linkify } from '../Linkify';
import { copyToClipboard } from '../../utils/link';
import NavigationBar from '../Home/NavigationBar';
import { useNavigate } from 'react-router-dom';

import UploadIcon from '../../assets/upload_icon.svg';

function ResultPage() {
  const navigate = useNavigate();

  const resultLink = new URLSearchParams(window.location.search).get('result');
  const result = resultLink ? decodeURIComponent(resultLink) : undefined;
  const blobPreview = new URLSearchParams(window.location.search).get('blob');

  const renderImageSection = useMemo(() => {
    return blobPreview && <PreviewImage src={blobPreview} />;
  }, [blobPreview]);

  const copyDataHandler = () => {
    if (!result) {
      alert('에러가 발생했어요.');
      return;
    }
    copyToClipboard(result);
    alert('클립보드에 복사되었습니다.\n원하는 곳에 붙여넣기 해주세요.');
  };

  const handleChangeQrCodeImage = () => {
    navigate('/');
  };

  return (
    <>
      <NavigationBar />
      <View>
        <Title>
          QR코드가 포함된
          <br />
          이미지를 업로드하세요.
        </Title>
        <Wrapper>
          <Main htmlFor="input-image">
            <ImagePreviewSection>{renderImageSection}</ImagePreviewSection>

            <Result
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Linkify>{result}</Linkify>
              <CopyButtonWrapper>
                <CopyButton onClick={copyDataHandler}>Copy</CopyButton>
              </CopyButtonWrapper>
            </Result>
            <UploadButton onClick={handleChangeQrCodeImage}>
              <UploadIconImg src={UploadIcon} />
              <UploadButtonText>다른 QR코드 확인하기</UploadButtonText>
            </UploadButton>
          </Main>
          <Footer>사진 속 QR코드의 정보를 분석해서 알려드려요</Footer>
        </Wrapper>
      </View>
    </>
  );
}
export default ResultPage;

const View = styled.div`
  width: 100%;
  min-height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;

  overflow-y: auto;
`;

const Title = styled.h1`
  font-size: 30px;
  font-weight: 700;
  line-height: 46px;
  color: #454545;
  text-align: center;
  margin: 30px 0;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  max-width: 18.75rem;
  /* margin:auto; */

  border-radius: 1.25rem;
  box-shadow: 0 0 2.5rem rgb(0 0 0 / 10%);
`;

const Main = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  padding: 32px;
  cursor: pointer;
`;

const ImagePreviewSection = styled.div`
  margin: 3rem 6.25rem 2.1875rem 6.25rem;
`;

const ImageIconImg = styled.img`
  width: 5rem;
`;

const PreviewImage = styled.img`
  width: 5rem;

  border-radius: 1.25rem;
`;

const UploadButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 0.75rem 2rem;

  background-color: #0f70e6;
  border-color: #0f70e6;
  color: #fff;
  border-radius: 6.1875rem;
  font-size: 1.0625rem;
  line-height: 1.5;

  font-weight: 700;
  cursor: pointer;
`;

const UploadButtonText = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  width: auto;
`;

const UploadIconImg = styled.img`
  width: 1.25rem;
`;

const Result = styled.div`
  width: 100%;
  padding: 16px 0 32px 0;
  word-break: break-all;
`;

const Footer = styled.div`
  width: 100%;
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.03);
  text-align: center;
  color: #747474;
  font-size: 12.8px;
  font-weight: 400;
`;

const CopyButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-top: 1rem;
  cursor: pointer;
`;

const CopyButton = styled.div`
  width: fit-content;
  border: 0.0625rem solid #dee2e6;
  color: #747474;
  text-align: center;
  font-size: 80%;
  font-weight: 400;
  padding: 4px;
  border-radius: 4px;
`;
